@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: tahoma;
    src: url(/src/fonts/TahomaRegular.ttf);
}

@layer base {
    h1{
        @apply font-[Sora] font-bold text-verdeN
        text-[24px] md:text-[36px] text-center leading-[30.24px] md:leading-[40px]
        lg:text-[45px] isXl:text-left lg:leading-[56.7px]
        max-w-[577px] mx-auto
    }

    h2 {
        @apply font-[Sora] font-bold text-white text-center
        text-[28px] md:text-[34px] lg:text-[44px] 
        leading-[32px] md:leading-[36px] lg:leading-[40px]
    }

    h3 {
        @apply font-[Sora] font-bold text-verdeN text-center
        text-[24px] md:text-[32px] lg:text-[36px] 
    }

    h4 {
        @apply font-[Sora] font-bold text-verdeN
        text-[20px] text-center
    }
    button{
        font-family: tahoma;
        font-weight: 700;
        @apply rounded-[40px] sm:rounded-[25px]
        bg-[#F64B25] text-white text-center
        flex items-center justify-center
         text-[20px]
    }
    a, .btn-w {
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
}

@layer components {
    .pNav {
        font-family: tahoma;
        @apply text-center text-white
        text-[24px] cursor-pointer
    }
    .pNavLine {
        font-family: tahoma;
        @apply text-[18px] cursor-pointer
        hover-underline-animation
    }
}

@layer utilities {

    .containerNav{
        @apply 
        max-w-[1167px] mx-auto px-[4%]
        lg:px-[8%] sm:px-[5%] xl:px-0 w-full
    }

    .containerW-1180{
        @apply 
        max-w-[1180px] mx-auto px-[5%]
        lg:px-[8%] sm:px-[5%] xl:px-0
    }

    .containerW-1024{
        @apply 
        max-w-[1024px] mx-auto px-[5%]
        lg:px-[8%] sm:px-[6%] xl:px-0
    }

    .containerW-1134{
        @apply 
        max-w-[1134px] mx-auto px-[5%]
        sm:px-[6%] lg:px-[8%] xl:px-0
    }

    .containerW-1236{
        @apply 
        max-w-[1236px] mx-auto px-[5%]
        lg:px-[6%] sm:px-[5%] xl:px-0
    }
}

    .hover-underline-animation {
        display: inline-block;
        position: relative;
        color: #000;
    }
  
    .hover-underline-animation::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: #000;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }
  
    .hover-underline-animation:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

  .hoverbt {
    color: #fff;
    background: #F64B25;
    border: none;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
  }
  .hoverbt:hover {
    background: #89C241;
    color: #fff;
  }

  .hoverbt2 {
    color: #fff;
    background: #89C241;
    border: none;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
  }
  .hoverbt2:hover {
    background: #F64B25;
    color: #fff;
  }
  
   .wrapper {
    display: inline-block;
    border-radius: "25px";
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
   }
    .wrapper:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border-radius: "25px";
      z-index: -2;
    }
    .wrapper:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #89C241;
      transition: all .3s;
      border-radius: "25px";
      z-index: -1;
      color: white
    }
    .wrapper:hover:before {
        width: 100%;
      }